<template>
  <div class="container">
    <!-- Dropdown Menüsü -->
    <div class="select-league">
      <select v-model="selectedLeague" @change="fetchMatchResults">
        <option disabled value="">Lig Seçimi</option>
        <option v-for="sport in sports" :key="sport.key" :value="sport.key">
          {{ sport.title }}
        </option>
      </select>
    </div>

    <!-- Maç Sonuçları Tablosu -->
    <div class="table-wrapper" v-if="results.length">
      <table class="custom-table">
        <thead>
        <tr>
          <th>Lig</th>
          <th>Ev Sahibi Takım</th>
          <th>Deplasman Takım</th>
          <th>Skor</th>
          <th>Durum</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="result in results" :key="result.id">
          <td>{{ result.title }}</td>
          <td>{{ result.homeTeam }}</td>
          <td>{{ result.awayTeam }}</td>
          <td>{{ result.homeScore }} - {{ result.awayScore }}</td>
          <td>{{ getStatus(result) }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <p v-else class="no-results">Seçilen lig için uygun maç bulunamadı.</p>

    <!-- Loading Spinner -->
    <LoadingSpinner v-if="loading" />

    <!-- Error Modal -->
    <ErrorModal v-if="error" :error="error" @close="error = null" />
  </div>
</template>

<script>
import '../assets/global.css'; // global.css dosyasını uygulamanıza dahil edin

import axios from 'axios';
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ErrorModal from "@/components/ErrorModal.vue";

export default {
  components: {
    LoadingSpinner,
    ErrorModal,
  },
  data() {
    return {
      sports: [],
      selectedLeague: '',
      results: [],
      loading: false,
      error: null
    };
  },
  async created() {
    const token = localStorage.getItem('jwt');

    // Spor dallarını API'den çek
    this.loading = true;
    try {
      const response = await axios.get('http://91.198.66.184:9090/kuponstar/coupon/sports', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      this.sports = response.data;

      if (this.sports.length > 0) {
        this.selectedLeague = this.sports[0].key;
        this.fetchMatchResults();
      }
    } catch (error) {
      this.error = 'Error fetching sports.';
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getStatus(event) {
      if (event.completed == "true") return "Tamamlandı";
      return "Devam Ediyor";
    },
    async fetchMatchResults() {
      if (!this.selectedLeague) return;

      const token = localStorage.getItem('jwt');
      this.loading = true;
      try {
        const response = await axios.get(
            `http://91.198.66.184:9090/kuponstar/api/odds/scores?leagueName=${this.selectedLeague}&daysFrom=1`,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
        );
        this.results = response.data;
      } catch (error) {
        this.error = 'Error fetching match results.';
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    // Sayfa yüklenince veri çek ve her 10 saniyede bir tekrar veri çek
    this.fetchMatchResults();
    this.intervalId = setInterval(this.fetchMatchResults, 10000); // 10 saniye aralıkla veri çekme
  },
  beforeUnmount() {
    // Component yok edilirken interval'i temizle
    clearInterval(this.intervalId);
  }
};
</script>

<style scoped>
/* Main container */
.container {
  width: 80%;
  margin: 20px auto;
  text-align: center;
}

.select-league {
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--primary-text-color);
}
/* Select League Dropdown */
.select-league select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid var(--primary-bg-color);
  background-color: var(--secondary-color);
  font-size: 16px;
}

/* Table wrapper for scrolling */
.table-wrapper {
  overflow-x: auto;
  margin-top: 20px;
}

/* Custom table styling */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--border-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.custom-table th,
.custom-table td {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid var(--border-color);
}

.custom-table th {
  background-color: var(--secondary-color);
  color: white;
}

.custom-table td {
  text-align: center;
}

.custom-table tr:hover {
  background-color: var(--secondary-color);
}

/* No results message */
.no-results {
  margin-top: 20px;
  color: var(--primary-text-color);
}

/* Spinner and Error Modal */
.error-modal {
  color: red;
}

</style>

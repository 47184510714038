import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Router'ı ekleyelim
import axios from 'axios';

const app = createApp(App);

// Axios Interceptor: Tüm API isteklerinde 403 yakala ve login'e yönlendir
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 403) {
            // Eğer 403 hatası alırsak, login sayfasına yönlendir
            router.push('/login');
        }
        return Promise.reject(error);
    }
);

app.use(router);
app.mount('#app');
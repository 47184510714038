<template>
  <nav v-if="isLoggedIn" class="navbar">
    <div class="navbar-container">
      <router-link class="logo" to="/matches">
        <img src="../assets/logo.png" alt="KuponStars Logo" class="logo-img" />
      </router-link>

      <ul class="nav-links">
        <!-- Eğer kullanıcı giriş yaptıysa menü öğelerini göster -->
        <li v-if="isLoggedIn">
          <router-link to="/matches">Karşılaşmalar</router-link>
        </li>
        <li v-if="isLoggedIn">
          <router-link to="/scores">Canlı Sonuçlar</router-link>
        </li>
        <li v-if="isLoggedIn">
          <router-link to="/coupons">Kuponlarım</router-link>
        </li>
      </ul>

      <!-- Kullanıcı bilgisi sağda gösterilecek -->
      <div v-if="isLoggedIn" class="user-info">
        <span>{{ userInfo.username }}</span>
        <span>KS Gold: {{ userInfo.coins }}</span>
        <!-- Çıkış Butonu -->
        <a href="#" @click="logout" class="logout-btn">Çıkış</a>
      </div>
    </div>
  </nav>
</template>

<script>
import '../assets/global.css'; // global.css dosyasını uygulamanıza dahil edin

import axios from 'axios';

export default {
  data() {
    return {
      userInfo: {},  // Kullanıcı bilgisi
      isLoggedIn: false  // Giriş durumu
    };
  },
  async created() {
    // Kullanıcının giriş yapıp yapmadığını kontrol et
    const token = localStorage.getItem('jwt');
    if (token) {
      this.isLoggedIn = true;
      // Kullanıcı bilgilerini API'den çek
      try {
        const response = await axios.get('http://91.198.66.184:9090/kuponstar/userInfo', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.userInfo = response.data;  // Kullanıcı bilgilerini al
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    }
  },
  methods: {
    async logout() {
      // Çıkış işlemi, token'ı sil
      localStorage.removeItem("jwt");

      // Giriş sayfasına yönlendir ve durumu güncelle
      this.$router.push('/login').then(() => {
        // Yönlendirme tamamlandıktan sonra sayfayı yenile
        window.location.reload();
      });
    }
  }
};
</script>

<style scoped>
.navbar {
  background-color: var(--border-color);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 30px;
  margin-top: 50px;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  color: var(--primary-text-color);
  font-weight: bold;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-links a:hover {
  background-color: var(--secondary-color);
}

/* Kullanıcı bilgisi (username ve coins) sağa yerleşir */
.user-info {
  display: flex;
  align-items: center;
  gap: 20px; /* Çıkış butonuna yer açmak için aralığı artırdım */
  color: var(--primary-text-color);
  font-weight: bold;
  font-size: 16px;
}

/* Çıkış butonunu kullanıcı bilgilerinin sağına koyduk */
.logout-btn {
  background-color: transparent;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
}

.logout-btn:hover {
  background-color: #e74c3c;
}
</style>

<template>
  <div class="container">

    <div class="back-to-coupons">
      <router-link to="/coupons">
        <button class="back-btn">Kuponlara Geri Dön</button>
      </router-link>
    </div>
    <!-- Coupon Details Table -->
    <div class="table-wrapper">
      <table class="custom-table">
        <thead>
        <tr>
          <th>Ev Sahibi Takım</th>
          <th>Deplasman Takımı</th>
          <th>Seçim</th>
          <th>Oran</th>
          <th>Sonuç</th>
          <th>Durum</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="event in events" :key="event.id">
          <td>{{ event.homeTeam }}</td>
          <td>{{ event.awayTeam }}</td>
          <td>{{ event.selection }}</td>
          <td>{{ event.odds }}</td>
          <td>
              <span v-if="event.homeScore !== undefined && event.awayScore !== undefined">
                {{ event.homeScore }} - {{ event.awayScore }}
              </span>
            <span v-else>Not Completed</span>
          </td>
          <td>{{ event.status }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- Toplam Oran ve Kazanç -->
    <div class="totals">
      <p><strong>Toplam Oran:</strong> {{ totalOdds.toFixed(2) }}</p>
      <p><strong>Toplam Kazanç:</strong> {{ totalIncome.toFixed(2) }} ₺</p>
    </div>
  </div>
</template>

<script>
import '../assets/global.css'; // global.css dosyasını uygulamanıza dahil edin

import axios from 'axios';

export default {
  data() {
    return {
      events: [],  // Kuponun maç detayları
      totalOdds: 0, // Toplam oran (varsayılan olarak 0)
      totalIncome: 0 // Toplam kazanç (varsayılan olarak 0)
    };
  },
  async created() {
    const token = localStorage.getItem('jwt');
    const couponId = this.$route.params.couponId;  // URL'den kupon ID'si alınır
    try {
      const response = await axios.get(`http://91.198.66.184:9090/kuponstar/coupon/detail?couponId=${couponId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      this.events = response.data.couponDetails;  // Maç detayları yüklenir
      this.totalOdds = response.data.totalOdds || 0;  // Toplam oran (null ise 0 yap)
      this.totalIncome = response.data.totalIncome || 0;  // Toplam kazanç (null ise 0 yap)

    } catch (error) {
      console.error('Error fetching coupon details:', error);
    }
  },

  methods: {
  async fetchData() {
    const token = localStorage.getItem('jwt');
    const couponId = this.$route.params.couponId;  // URL'den kupon ID'si alınır
    try {
      const response = await axios.get(`http://91.198.66.184:9090/kuponstar/coupon/detail?couponId=${couponId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      this.events = response.data.couponDetails;  // Maç detayları yüklenir
      this.totalOdds = response.data.totalOdds || 0;  // Toplam oran (null ise 0 yap)
      this.totalIncome = response.data.totalIncome || 0;  // Toplam kazanç (null ise 0 yap)

    } catch (error) {
      console.error('Error fetching coupon details:', error);
    }
  },

  },
  mounted() {
    // Sayfa yüklenince veri çek ve her 10 saniyede bir tekrar veri çek
    this.fetchData();
    this.intervalId = setInterval(this.fetchData, 10000); // 10 saniye aralıkla veri çekme
  },
  beforeUnmount() {
    // Component yok edilirken interval'i temizle
    clearInterval(this.intervalId);
  }

};
</script>

<style scoped>
/* Main container */
.container {
  width: 80%;
  margin: 20px auto;
}

/* Table wrapper for scrolling */
.table-wrapper {
  overflow-x: auto;
  margin-top: 20px;
}

/* Custom table styling */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--border-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.custom-table th,
.custom-table td {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid var(--border-color);
}

.custom-table th {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
}

.custom-table td {
  text-align: center;
}

.custom-table tr:hover {
  background-color: var(--secondary-color);
}

/* Back to My Coupons button */
.back-to-coupons {
  text-align: left;
  margin-top: 50px;
}

.back-btn {
  background-color:var(--border-color);
  color: var(--primary-text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.back-btn:hover {
  background-color: var(--secondary-color);
}

/* Toplam oran ve kazanç */
.totals {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}
</style>

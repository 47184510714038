<template>
  <div class="error-modal">
    <div class="error-modal-content">
      <span class="close" @click="$emit('close')">&times;</span>
      <h2>Hata!</h2>
      <p>{{ message }}</p>
      <button @click="$emit('close')" class="modal-btn">Tamam</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.error-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.error-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.error-modal-content h2 {
  color: #e74c3c;
  margin-bottom: 20px;
}

.error-modal-content p {
  color: #333;
  margin-bottom: 20px;
}

.modal-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-btn:hover {
  background-color: #c0392b;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.close:hover {
  color: #e74c3c;
}
</style>

<template>
  <div class="container">
    <!-- Coupons Table -->
    <div class="table-wrapper">
      <table class="custom-table">
        <thead>
        <tr>
          <th>Misli</th>
          <th>Durum</th>
          <th>Oluşturma Zamanı</th>
          <th>Toplam Oran</th>
          <th>Toplam Kazanç</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="coupon in coupons" :key="coupon.id">
          <td>{{ coupon.betAmount }} ₺</td>
          <td>{{ coupon.status }}</td>
          <td>{{ new Date(coupon.createdAt).toLocaleString() }}</td>
          <td>{{ coupon.totalOdds }}</td>
          <td>{{ coupon.totalIncome }} ₺</td>
          <td>
            <router-link :to="{ name: 'CouponDetail', params: { couponId: coupon.id } }">
              <button class="details-btn">Görüntüle</button>
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- Loading Spinner -->
    <LoadingSpinner v-if="loading" />

    <!-- Error Modal -->
    <ErrorModal v-if="error" :error="error" @close="error = null" />
  </div>
</template>

<script>
import '../assets/global.css'; // global.css dosyasını uygulamanıza dahil edin

import axios from 'axios';
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ErrorModal from "@/components/ErrorModal.vue";

export default {
  components: {
    LoadingSpinner,
    ErrorModal,
  },
  data() {
    return {
      coupons: [],  // Kuponlar bu değişkene yüklenecek
      loading: false,
      error: null,
    };
  },
  async created() {
    const token = localStorage.getItem('jwt');
    this.loading = true;
    try {
      const response = await axios.get('http://91.198.66.184:9090/kuponstar/coupon', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      this.coupons = response.data;  // Kupon verileri yüklendi
    } catch (error) {
      this.error = 'Error fetching coupons.';
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async fetchData() {
      const token = localStorage.getItem('jwt');
      this.loading = true;
      try {
      const response = await axios.get('http://91.198.66.184:9090/kuponstar/coupon', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      this.coupons = response.data;  // Kupon verileri yüklendi
    } catch (error) {
      this.error = 'Error fetching coupons.';
    } finally {
      this.loading = false;
    }
    }
  },
  mounted() {
    // Sayfa yüklenince veri çek ve her 10 saniyede bir tekrar veri çek
    this.fetchData();
    this.intervalId = setInterval(this.fetchData, 10000); // 10 saniye aralıkla veri çekme
  },
  beforeUnmount() {
    // Component yok edilirken interval'i temizle
    clearInterval(this.intervalId);
  }
};
</script>

<style scoped>
/* Main container */
.container {
  width: 80%;
  margin: 20px auto;
}

/* Table wrapper for scrolling */
.table-wrapper {
  overflow-x: auto;
  margin-top: 50px;
}

/* Custom table styling */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--border-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.custom-table th,
.custom-table td {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid var(--border-color);
}

.custom-table th {
  background-color: var(--secondary-color);
  color: white;
}

.custom-table td {
  text-align: center;
}

.custom-table tr:hover {
  background-color: var(--secondary-color);
}

/* View details button styling */
.details-btn {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.details-btn:hover {
  background-color: var(--border-color);
}
</style>

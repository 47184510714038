<template>
  <div class="container">
    <!-- Sport Selection -->
    <div class="select-sport">
      <select v-model="selectedSport" @change="fetchMatches">
        <option disabled value="">Lig Seçimi</option>
        <option v-for="sport in sports" :key="sport.key" :value="sport.key">
          {{ sport.title }}
        </option>
      </select>
    </div>

    <!-- Matches Table -->
    <div v-if="matches.length" class="matches-container">
      <div class="table-wrapper">
        <table class="custom-table">
          <thead>
          <tr>
            <th>Ev Sahibi Takım</th>
            <th>Deplasman Takımı</th>
            <th>Karşılaşma Tarihi</th>
            <th>Ev Sahibi Oranı</th>
            <th>Beraberlik Oranı</th>
            <th>Deplasman Oranı</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="match in matches" :key="match.id">
            <td>{{ match.homeTeam }}</td>
            <td>{{ match.awayTeam }}</td>
            <td>{{ new Date(match.time).toLocaleString() }}</td>
            <td
                :class="{ selected: isSelected(match, 'HOME') }"
                @click="toggleSelection(match, 'HOME')"
            >
              {{ match.homeRate }}
            </td>
            <td
                :class="{ selected: isSelected(match, 'AWAY') }"
                @click="toggleSelection(match, 'AWAY')"
            >
              {{ match.drawRate }}
            </td>
            <td
                :class="{ selected: isSelected(match, 'DRAW') }"
                @click="toggleSelection(match, 'DRAW')"
            >
              {{ match.awayRate }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <p v-else class="no-matches">Seçilen lig için uygun maç bulunamadı.</p>

    <!-- Sepet İkonu ve Modal -->
    <div v-if="selectedEvents.length" class="basket-icon" @click="showBasketModal = true">
      🛒 <span>{{ selectedEvents.length }}</span>
    </div>

    <!-- Basket Modal -->
    <div v-if="showBasketModal" class="modal-overlay">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>

        <!-- Başarı mesajı -->
        <div v-if="successMessage" class="success-message">
          <p>{{ successMessage }}</p>
        </div>

        <!-- Seçilen Maçlar Tablosu -->
        <table class="selected-events-table" v-if="!successMessage">
          <thead>
          <tr>
            <th>Ev Sahibi Takım</th>
            <th>Deplasman Takımı</th>
            <th>Seçim</th>
            <th>Oran</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="event in selectedEvents" :key="event.eventId">
            <td>{{ event.match.homeTeam }}</td>
            <td>{{ event.match.awayTeam }}</td>
            <td>{{ getSelection(event) }}</td>
            <td>{{ getOdds(event) }}</td>
            <td>
              <button @click="removeEvent(index)" class="remove-btn">×</button>
            </td>
          </tr>
          </tbody>
        </table>

        <!-- Toplam Oran ve Kazanç -->
        <div class="totals" v-if="!successMessage">
          <p><strong>Toplam Oran:</strong> {{ totalOdds.toFixed(2) }}</p>
          <p><strong>Kazanç:</strong> {{ potentialWinning }} ₺</p>
        </div>

        <!-- Misli Girme Alanı -->
        <div class="bet-amount-card" v-if="!successMessage">
          <input
              v-model="amount"
              type="number"
              id="betAmount"
              class="bet-input"
              placeholder="Misli Değerini Gir"
          />
        </div>

        <button v-if="!successMessage" @click="submitCoupon" class="submit-btn">Kuponu Oluştur</button>
      </div>
    </div>

    <!-- Loading Spinner -->
    <LoadingSpinner v-if="loading" />

    <!-- Error Modal -->
    <ErrorModal v-if="error" :error="error" @close="error = null" />
  </div>
</template>

<script>
import '../assets/global.css'; // global.css dosyasını uygulamanıza dahil edin

import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ErrorModal from "@/components/ErrorModal.vue";

export default {
  components: {
    LoadingSpinner,
    ErrorModal,
  },
  data() {
    return {
      sports: [],
      selectedSport: "",
      matches: [],
      selectedEvents: [],
      amount: 0,
      loading: false,
      error: null,
      showBasketModal: false, // Sepet modalını kontrol eden flag
      successMessage: "", // Başarı mesajı için
    };
  },
  computed: {
    totalOdds() {
      return this.selectedEvents.reduce((total, event) => {
        return total + this.getOdds(event);
      }, 1);
    },
    potentialWinning() {
      return (this.totalOdds * this.amount).toFixed(2);
    },
  },
  async created() {
    const token = localStorage.getItem("jwt");
    this.loading = true;
    try {
      const response = await axios.get("http://91.198.66.184:9090/kuponstar/coupon/sports", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.sports = response.data;

      if (this.sports.length > 0) {
        this.selectedSport = this.sports[0].key;
        this.fetchMatches();
      }
    } catch (error) {
      this.error = "Error fetching sports.";
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async fetchMatches() {
      if (!this.selectedSport) return;

      const token = localStorage.getItem("jwt");
      this.loading = true;
      try {
        const response = await axios.get(
            `http://91.198.66.184:9090/kuponstar/api/odds?leagueName=${this.selectedSport}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        );
        this.matches = response.data;
      } catch (error) {
        this.error = "Error fetching matches.";
      } finally {
        this.loading = false;
      }
    },
    toggleSelection(match, selection) {
      const existingEventIndex = this.selectedEvents.findIndex(
          (event) => event.eventId === match.id
      );

      if (existingEventIndex !== -1) {
        const existingEvent = this.selectedEvents[existingEventIndex];
        if (existingEvent.selection === selection) {
          this.selectedEvents.splice(existingEventIndex, 1); // Seçimi kaldır
        } else {
          this.selectedEvents[existingEventIndex].selection = selection; // Seçimi güncelle
        }
      } else {
        this.selectedEvents.push({
          eventId: match.id,
          selection,
          leagueName: this.selectedSport,
          match,
        });
      }
    },
    isSelected(match, selection) {
      return !!this.selectedEvents.find(
          (event) => event.eventId === match.id && event.selection === selection
      );
    },
    getOdds(event) {
      if (event.selection === "HOME") return event.match.homeRate;
      if (event.selection === "AWAY") return event.match.awayRate;
      if (event.selection === "DRAW") return event.match.drawRate;
      return 0;
    },

    getSelection(event) {
      if (event.selection === "HOME") return "Ev Sahibi";
      if (event.selection === "AWAY") return "Deplasman";
      if (event.selection === "DRAW") return "Berabere";
      return 0;
    },
    removeEvent(index) {
      if (this.selectedEvents.length > 1) {
        this.selectedEvents.splice(index, 1); // Seçili olayı listeden çıkar
      } else {
        alert("Sepette en az bir maç bulunmalıdır.");
      }
    },
    async submitCoupon() {
      const token = localStorage.getItem("jwt");
      const couponData = {
        amount: this.amount,
        events: this.selectedEvents,
      };

      this.loading = true;
      try {
        await axios.post("http://91.198.66.184:9090/kuponstar/coupon", couponData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Kupon başarılı olursa modal içinde başarı mesajı göster
        this.successMessage = "Kuponunuz başarılı bir şekilde kaydedilmiştir. Kuponlarım sekmesinden takip edebilirsiniz.";
        // Temizlik işlemleri
        this.selectedEvents = [];
        this.amount = 0;
      } catch (error) {
        this.error = "Failed to submit coupon.";
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.showBasketModal = false;
      this.successMessage = ""; // Modal kapandığında başarı mesajını temizle
    },
  },
};
</script>

<style scoped>
/* Main container */
.container {
  width: 80%;
  margin: 20px auto;
}

/* Select sport dropdown */
.select-sport {
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--primary-text-color);
}

.select-sport select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid var(--primary-bg-color);
  background-color: var(--secondary-color);
  font-size: 16px;
}

/* Table wrapper for scrolling and custom look */
.table-wrapper {
  overflow-x: auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--border-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.custom-table th,
.custom-table td {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid var(--border-color);
}

.custom-table th {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);;
}

.custom-table td {
  text-align: center;
}

.custom-table tr:hover {
  background-color: var(--secondary-color);
}

.custom-table td.selected {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);;
  font-weight: bold;
}

/* Sepet ikonu */
.basket-icon {
  font-size: 24px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--secondary-color);
  color: var(--primary-text-color);;
  padding: 10px;
  border-radius: 25%;
}

/* Modal için stil */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modal-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: var(--primary-bg-color);;
  padding: 20px;
  border-radius: 8px;
  width: 500px; /* Modal genişliğini artırdık */
  position: relative;
}

.modal-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: var(--primary-bg-color);
}

.selected-events-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--border-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.selected-events-table th,
.selected-events-table td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid var(--primary-text-color);
}

.selected-events-table th {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);;
}

.bet-amount-card {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bet-input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submit-btn {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
}

.submit-btn:hover {
  background-color: var(--primary-bg-color);
}

/* Başarı mesajı */
.success-message {
  text-align: center;
  color: var(--primary-text-color);
  font-weight: bold;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--border-color);
}

/* Toplam oran ve kazanç */
.totals {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

.remove-btn {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

</style>

<template>
  <div class="auth-container">

    <img src="@/assets/login-logo.png" alt="KuponStars Logo" class="logo-img" />

    <!-- Tab Navigation -->
    <div class="tabs">
      <button
          class="tab"
          :class="{ active: activeTab === 'login' }"
          @click="activeTab = 'login'"
      >
        Giriş
      </button>
      <button
          class="tab"
          :class="{ active: activeTab === 'register' }"
          @click="activeTab = 'register'"
      >
        Kayıt Ol
      </button>
    </div>

    <!-- Login Form -->
    <div v-if="activeTab === 'login'" class="form-box">
      <form @submit.prevent="login">
        <input
            v-model="loginForm.username"
            type="text"
            placeholder="Username"
            required
        />
        <div class="password-container">
          <input
              v-model="loginForm.password"
              :type="passwordVisible ? 'text' : 'password'"
              placeholder="Password"
              required
          />
          <div class="toggle-password" @click="togglePasswordVisibility">
            {{ passwordVisible ? '🙈' : '👁️' }}
          </div>
        </div>
        <button type="submit" class="submit-btn">Giriş</button>
      </form>
    </div>

    <!-- Register Form -->
    <div v-if="activeTab === 'register'" class="form-box">
      <form @submit.prevent="register">
        <input
            v-model="registerForm.username"
            type="text"
            placeholder="Username"
            required
        />
        <div class="password-container">
          <input
              v-model="registerForm.password"
              :type="passwordVisible ? 'text' : 'password'"
              placeholder="Password"
              required
          />
          <div class="toggle-password" @click="togglePasswordVisibility">
            {{ passwordVisible ? '🙈' : '👁️' }}
          </div>
        </div>
        <input
            v-model="registerForm.email"
            type="email"
            placeholder="Email"
            required
        />
        <button type="submit" class="submit-btn">Kayıt Ol</button>
      </form>
    </div>

    <!-- Global Loading Spinner ve Error Modal -->
    <LoadingSpinner v-if="loading" />
    <ErrorModal v-if="error" :message="errorMessage" @close="closeErrorModal" />
  </div>
</template>

<script>
// main.js
import '../assets/global.css'; // global.css dosyasını uygulamanıza dahil edin

import axios from "axios";
import ErrorModal from './ErrorModal.vue';
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  components: {
    ErrorModal,
    LoadingSpinner
  },
  data() {
    return {
      activeTab: "login", // Default olarak login tab aktif
      loginForm: {username: "", password: ""},
      registerForm: {username: "", password: "", email: ""},
      loading: false, // Global loading durumu
      error: false, // Global hata modal durumu
      errorMessage: "", // Hata mesajı
      passwordVisible: false // Şifre görünürlüğü için durum
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    async login() {
      this.loading = true; // Loading spinner'ı göster
      try {
        const response = await axios.post(
            "http://91.198.66.184:9090/kuponstar/authenticate/login",
            this.loginForm
        );
        localStorage.setItem("jwt", response.data.jwt);
        this.$router.push('/matches').then(() => {
          // Yönlendirme tamamlandıktan sonra sayfayı yenile
          window.location.reload();
        });
      } catch (error) {
        this.showError("Giriş sırasında hata oluştu.");
      } finally {
        this.loading = false; // Loading spinner'ı gizle
      }
    },
    async register() {
      this.loading = true; // Loading spinner'ı göster
      try {
        await axios.post(
            "http://91.198.66.184:9090/kuponstar/authenticate/register",
            this.registerForm
        );
        this.$router.push("/login");
      } catch (error) {
        this.showError("Kayıt işlemi sırasında hata oluştu.");
      } finally {
        this.loading = false; // Loading spinner'ı gizle
      }
    },
    showError(message) {
      this.errorMessage = message;
      this.error = true; // Hata modalını göster
    },
    closeErrorModal() {
      this.error = false; // Hata modalını kapat
    }
  }
};
</script>

<style scoped>
.auth-container {
  width: 100%;
  max-width: 500px;
  margin: 10% auto;
  padding: 40px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--border-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabs {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.tab {
  width: 50%;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-bottom: 2px solid transparent;
  background-color: var(--border-color);
  font-weight: bold;
  color: var(--primary-text-color);
  text-align: center;
}

.tab.active {
  border-bottom: 2px solid var(--secondary-color);
}

.form-box {
  width: 100%;
}

.password-container {
  display: flex;
  align-items: center;
}

.toggle-password {
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px;
}

input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  font-size: 16px;
}

.submit-btn {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.submit-btn:hover {
  background-color: var(--primary-bg-color);
}

@media (max-width: 768px) {
  .auth-container {
    padding: 20px;
  }

  .tab {
    font-size: 14px;
    padding: 10px;
  }
}
</style>

<template>
  <div id="app">
    <!-- Navbar'ı uygulamaya ekle -->
    <NavbarComponent />
    <!-- Sayfa İçeriği -->
    <router-view></router-view>

  </div>
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue';


export default {
  components: {
    NavbarComponent
  },
};
</script>

<style>
@import './assets/global.css'; /* Global CSS ve fontlar */
</style>

import { createRouter, createWebHistory } from 'vue-router';
import Matches from '../components/AllMatches.vue';
import Scores from '../components/MatchScores.vue';
import Coupons from '../components/UserCoupons.vue';
import CouponDetail from '../components/CouponDetail.vue';
import Login from "@/components/UserLogin.vue";

// Define routes
const routes = [
  { path: '/login', component: Login },
  { path: '/matches', component: Matches },
  { path: '/scores', component: Scores },
  { path: '/coupons', component: Coupons },
  {
    path: '/coupon/:couponId',
    name: 'CouponDetail',
    component: CouponDetail,
    props: true // couponId parametresini props olarak geçiriyoruz
  }];


// Create router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('jwt'); // Check if JWT token exists

  if ((to.path !== '/login') && !isAuthenticated) {
    // If the user is not authenticated and trying to access a restricted page, redirect to login
    next('/login');
  } else {
    next(); // Otherwise, allow the navigation
  }
});

export default router;
